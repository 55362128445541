import { render, staticRenderFns } from "./iconTablet.vue?vue&type=template&id=50862c5b&scoped=true&"
import script from "./iconTablet.vue?vue&type=script&lang=js&"
export * from "./iconTablet.vue?vue&type=script&lang=js&"
import style0 from "./iconTablet.vue?vue&type=style&index=0&id=50862c5b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50862c5b",
  null
  
)

export default component.exports